#download-spinner {
    display: flex;    
    opacity: 0;
    z-index: 999;
    position: fixed;
    top: 70px;
    right: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: opacity .25s ease-in-out;
}

#download-spinner h1 {
    font-weight: 500;
    color: white;
    text-transform: uppercase;
}

.active {
    opacity: 1 !important;
}

.lds-ring {
    display: flex;
    position: relative;
    width: 80px;
    align-items: center;
    justify-content: center;
    top: -1px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    margin: 8px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .Message-wrapper {
    background-color: #cfcfcf;
  }

.Message {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  width: 330px;
  color: rgb(26,26,26);
  transition: all 0.2s ease;
  border-radius: 20px;
}

.Message-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  border-radius: 20px 0px 0px 20px;
  background-color: rgba(0, 0, 0, 0.25);  
}

.Message-icon > i {
  width: 20px;
  font-size: 20px;
}

.fa-check:before {
  content: "\f00c";
}

.Message-body {
  display: flex;
  align-items: center;
  padding: 20px 20px;
}

.Message-body p {
  position: relative;
  top: -2px;  
  color: rgb(26,26,26);
}

@media (prefers-color-scheme: dark) {
  .Message-wrapper {
    background-color: #404040;
  }

  .Message-body p {
    color: white;
  }  

  .lds-ring div {
    border-color: #fff transparent transparent transparent;
  }  
}