html {
  font-family: 'Montserrat', sans-serif;
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
p,
blockquote,
figure,
form,
fieldset,
input,
legend,
pre,
abbr,
button {
  margin: 0;
  padding: 0;
}

a:link,
a:visited,
.link:link,
.link:visited {
  color: black;
  text-decoration: none;
}

ul {
  list-style: none;
}

html {
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  padding: 0;

  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
  background: #F3F3F3 !important;
  height: 100%;
  margin:0;
}

#root {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(148 163 184 / 0.2)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e") !important;
}

@media (prefers-color-scheme: dark) {
  body {
    background: #202020 !important;
  }

  a:link,
  a:visited,
  .link:link,
  .link:visited {
    color: white;
  }
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 100vh;
  min-width: 100vw;
}

#root>nav {
  top: 0;
  z-index: 100;
  width: 100vw;
  max-width: 100vw;
  height: 58px;
  max-height: 54px;

  color: rgb(26,26,26);

  position: -webkit-sticky;
  position: sticky;

  display: flex;
  justify-content: center;
}

@media (prefers-color-scheme: dark) {
  #root>nav {
    color: white
  }
}

#root>nav>.profile {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  right: 0;
  height: 100%;
  padding: 0px 10px;
  border-radius: 0px 0px 0px 20px;
}

header>.content .list>.item {
  padding: 10px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

header>.content .list>.item.active,
header>.content .list>.item:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

footer {
  display: flex;
  justify-content: center;
  align-items: end;

  width: 100vw;
  margin-top: auto;
  padding: 50px 0px;
}

footer h1 {
  font-size: 1vw;
}

section {
  justify-content: center;
  width: 80vw;
}


@supports ((-webkit-backdrop-filter: saturate(180%) blur(20px)) or (backdrop-filter: saturate(180%) blur(20px))) {

  header>.content .list>.item.active,
  header>.content .list>.item:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }

  header>.content,
  #root nav>.profile {
    background: rgba(249, 249, 249, 0.3);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    box-shadow: 1px 4px 10px 0px rgb(88 92 98 / 50%);
  }

  @media (prefers-color-scheme: dark) {

    header>.content .list>.item.active,
    header>.content .list>.item:hover {
      background-color: rgba(255, 255, 255, 0.25);
    }

    header>.content,
    #root nav>.profile {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}


@media (prefers-color-scheme: dark) {
  .footer {
    background-color: rgb(18, 18, 18);
  }
}

.ta-promotion-item {
  position: absolute;

  user-select: none;
}

.ta-promotion-item>* {
  position: relative
}

.ta-promotion-item>span {
  color: rgba(255, 255, 255, 0.9);
  padding: 6px 13px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  font-weight: bold;

  user-select: none;
}

.notfound {
  text-align: center;
}

.notfound h1 {
  font-size: 22vh;
  color: rgb(252, 27, 21);
}

.dx-filemanager-item-custom-thumbnail {
  width: 100% !important;
  object-fit: contain !important;
}

.scrollbardiv {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: rgba(0,0,0,0);
  -webkit-background-clip: text;
  transition: background-color 1s;  
}

.scrollbardiv::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

.scrollbar:hover {
 background-color: rgba(0,0,0,0.2);  
 transition: background-color 1s;
}

.noscrollbar:hover {
 background-color: rgba(0,0,0,0);  
 transition: background-color 1s;
}

.scrollbardiv::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbardiv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: inherit;
}
